/*
* 语言文件 en：英文
* */
export const lang_en = {
    '账号安全':'Account security',
'尚未绑定手机号':'Phone number not yet bound',
'修改号码':'Change number',
'绑定号码':'Binding number',
'电子邮箱':'E-mail',
'绑定邮箱：':'Bind email:',
'若邮箱已停用，请及时更换':'If the email has been deactivated, please replace it promptly',
'尚未绑定邮箱':'Email not yet bound',
'手机号码':'Mobile phone number',
'绑定邮箱':'Bind email',
'登录密码':'Login password',
'修改密码':'Change password',
'设置密码':'Set password',
'修改邮箱':'Modify email',
'绑定手机：':'Binding phone:',
'若手机丢失或停用，请及时更换':'If your phone is lost or deactivated, please replace it promptly',
'重置密码':'reset password',
'地址管理':'Address Management',
'默认地址':'Default address',
'新增地址':'Add address',
'全部地址':'All addresses',
'设置为默认':'set as default',
'编辑地址':'Edit Address',
'删除地址':'Delete address',
'暂无收货地址～':'There is currently no shipping address~',
'添加收货地址':'Add shipping address',
'收货人':'consignee',
'请输入收货人姓名':"Please enter the recipient's name",
'电话号码':'Phone number:',
'在使用账户余额时需输入支付密码以保证您的资金安全':'When using account balance, you need to enter a payment password to ensure the security of your funds',
'所在地区':'Location',
'请选择所在地区':'Please select your region',
'详细地址':'Detailed address',
'请输入详细地址':'Please enter detailed address',
'设为默认地址':'Set as default address',
'取 消':'Cancel',
'确 定':'Confirm',
'我的退货':'My return',
'我的退款':'My refund',
'商品明细':'Product details',
'申请时间':'Application time',
'申请类型':'Application type',
'状态':'state',
'操作':'operation',
'下单时间':'Order time ',
'订单号':'Order number',
'查看详情':'View details',
'发货':'delivery',
'我已知悉？':'Do I know?',
'去注册':'Go register',
'添加/编辑':'Add/Edit',
'（同订单商品可批量申请）':'(Same order products can be applied for in bulk)',
'支付密码':'Payment password',
'退款金额不可修改，最多':'Refund amount cannot be modified, maximum',
'含运费':'Including shipping costs',
'不含运费':'Excluding shipping costs',
'修改金额':'Modify amount',
'退款金额可修改，最多':'Refund amount can be modified, up to',
'请输入退款金额':'Please enter the refund amount',
'取消':'Cancel',
'确定':'Determine',
'申请件数':'Number of applications',
'请输入申请件数':'Please enter the number of applications',
'货物状态':'Goods status',
'退款原因':'Reason for refund',
'退货原因':'Reason for return',
'请选择退款原因':'Please select a refund reason',
'请选择退货原因':'Please select the reason for return',
'问题描述':'Problem description',
'请输入问题描述(选填)':'Please enter a problem description (optional)',
'上传凭证':'Upload credentials',
'批量售后商品选择':'Batch after-sales product selection',
'首页':'home page',
'文章':'article',
'暂无文章数据':'There is currently no article data available',
'文章分类':'Article classification',
'最新文章':'Latest article',
'我的余额':'My balance',
'总金额':'Total amount',
'(元)：':'(Yuan):',
'可用余额':'Available balance',
'充值':'Recharge',
'提现':'Withdrawal',
'余额明细':'Balance details',
'充值明细':'Recharge details',
'提现明细':'Withdrawal details',
'交易日期':'Transaction date',
'收入/支出':'Income/expenditure',
'变动原因':'Reason for change',
'暂无余额明细~':'There is currently no balance details available~',
'充值金额':'Recharge amount',
'(元)':'(Yuan)',
'暂无充值明细~':'No recharge details currently available~',
'提现单号':'Withdrawal number',
'提现金额':'Withdrawal amount',
'手续费':'Handling fees',
'失败原因':'Reason for failure',
'￥':'￥',
'查看':'check',
'暂无提现明细~':'There are currently no withdrawal details available~',
'您尚未设置支付密码，请设置支付密码后再进行后续操作。':'You have not set a payment password yet. Please set a payment password before proceeding with further operations.',
'去设置':'Go set up',
'输入支付密码':'Enter payment password',
'请输入支付密码以完成后续操作':'Please enter the payment password to complete the subsequent operation',
'请输入平台支付密码':'Please enter the platform payment password',
'确认':'confirm',
'绑定手机号':'Bind phone number',
'请输入验证码':'Please enter the verification code',
's后获取':'s',
'获取验证码':'Obtain code',
'绑定':'binding',
'请输入正确的短信验证码':'Please enter the correct SMS verification code',
'密码最低6位':'Password with a minimum of 6 digits  ',
'密码输入不一致':'Inconsistent password input',
'该手机号已被绑定，请更换其他手机号':'The phone number has been bound, please change to another phone number',
'继续绑定：将解除与账号':'Continue binding: will be unbound from account',
'的绑定关系':'The binding relationship of',
'更新信息：授权信息将绑定到账号':'Update information: Authorization information will be bound to the account',
'继续绑定':'Continue binding',
'更新信息':'Update information',
'品牌中心':'Brand Center',
'全选':'Select All',
'商品信息':'Product information',
'单价':'unit price',
'总价':'Total price',
'数量':'quantity',
'小计（元）':'Subtotal (yuan)',
'联系客服':'Contact customer service',
'满减':'Full reduction',
'促销':'sales promotion',
'库存':'inventory',
'库存不足':'Insufficient inventory',
'剩余':'surplus',
'暂无已发货商品信息':'There is currently no information on shipped products',
'件':'piece',
'移入收藏夹':'Move to favorites',
'确定删除选中商品？':'Are you sure to delete the selected product?',
'删除':'delete',
'失效商品':'Invalid product',
'清空':'empty',
'失效':'Invalid',
'删除选中的商品':'Delete selected products',
'请输入手机号':'Please enter your phone number',
'已选商品':'Selected product',
'合计':'total',
'去结算':'Go settle',
'这里空空如也，快去首页逛逛吧~':'This place is empty, hurry up and take a look on the homepage~',
'去首页 > >':'Go to the homepage>>',
'确定清空失效商品？':'Are you sure to clear expired products?',
'购物车中还没有商品，赶紧选购吧':'There are no items in the shopping cart yet, hurry up and make a purchase',
'最新加入的商品':'Newly added products',
'共':'common',
'件商品':'Items',
'去购物车':'Go to the shopping cart',
'暂无数据':'There is currently no data available',
'发送链接 >':'Send link>',
'已读':'Read',
'未读':'Unread',
'订单号：':'Order number:',
'常见问题':'Frequently asked questions',
'暂未有常见问题~':'There are currently no common issues~',
'发送':'send',
'我的订单':'My order',
'我的足迹':'My Tracks ',
'店铺推荐':'Store recommendations',
'发送链接':'Send link',
'展开全部':'expand all',
'收起全部':'Put everything away',
'暂无订单～':'There are currently no orders~',
'暂无足迹～':'There are currently no footprints~',
'店铺名称：':'Store Name:',
'店铺星级：':'Store star rating:',
'联系电话：':'Contact phone number:',
'店铺评分':'Store rating',
'用户评价：':'User reviews:',
'高':'high',
'低':'low',
' 物流服务：':'Logistics services:',
'中':'in',
'售后服务：':'After sales service:',
'暂无店铺商品～':'There are currently no store products~',
'搜索最近联系人':'Search for recent contacts',
'搜索':'search',
'确定关闭该对话吗？':'Are you sure you want to close this conversation?',
'暂无数据～':'There is currently no data available~',
'收藏的商品':'Favorite items',
'收藏的店铺':'Favorite Stores',
'取消全选':'Deselect All',
'收货地址':'Receiving address',
'管理':'Administration',
'退款金额':'refund amount ',
'自营':'Self operated',
'进入店铺':'Enter shop',
'加入购物车':'Add to cart',
'特别关注':'Special attention',
'普通关注':'Regular follow',
'暂无收藏店铺':'There are currently no favorite stores available',
'设为特殊关注':'Set as special attention',
'取消特殊关注':'Cancel special follow',
'取消关注':'Cancel Follow',
'热销推荐':'Hot selling recommendations',
'本月上新':'Newly added this month',
'购物车':'shopping cart',
'确认订单':'Confirm order',
'付款':'payment',
'请选择接收语言':'Please select the receiving language',
'设置消息接收语言':'Set message receiving language',
'接收语言':'Receiving language',
'更改接收语言，可前往个人中心-->消息-->接收设置进行调整':'To change the receiving language, you can go to the personal center ->message ->receiving settings to make adjustments',
'支付成功':'Successful',
'填写并核对订单信息':'Fill in and verify order information',
'收货信息':'Receiving information',
'新增收货地址':'Add shipping address',
'收起地址':'Collapse address',
'暂无收货地址':'There is currently no shipping address available',
'预留信息':'Reserved information',
'请输入':'Please enter',
'商品清单':'Product List',
'返回修改购物车':'Return to modify shopping cart',
'店铺宝贝':'Shop Baby',
'小计':'Subtotal',
'店铺：':'Store:',
'虚拟':'fictitious',
'¥':'¥',
'x':'x',
'赠品':'Gifts',
'1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；':'1. After successful recharge, there may be a delay in the balance, which usually takes 1 to 5 minutes to arrive. If you have any questions, please consult customer service;',
'请输入金额':'Please enter the amount',
'赠完为止':'Until the end of the gift',
'给卖家留言：':'Leave a message for the seller:',
'选填，给卖家留言备注':'Optional, leave a note for the seller',
'使用优惠券':'Clip coupons ',
'暂无可用优惠券':'No available coupons available',
'成交量':'Turnover',
'请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账':'Please note: Alipay payment and WeChat payment are supported. After successful online payment, the recharge amount will be received within 1 to 5 minutes',
'不使用优惠券':'Do not use coupons',
'折':'fracture',
'优惠':'preferential',
'元':'element',
'商品金额':'Product amount',
'店铺总优惠':'Total store discount',
'运费':'freight',
'免运费':'Free shipping',
'发票':'invoice',
'我要开发票':'I need to issue an invoice',
'重新选择':'Re select',
'使用优惠券/积分抵用':'Use coupons/points to offset',
'平台优惠券':'Platform coupons',
'积分抵扣':'Point deduction',
'固定金额券':'Fixed amount voucher',
'折扣券':'coupon ',
'随机金额券':'Random amount coupon',
'使用规则：':'Usage rules:',
'可用积分':'Available points',
'积分抵现':'Points offset',
'订单金额':'Order amount',
'取消收藏':'Cancel Favorite',
'可用':'available',
'当前积分':'Current points',
'金额抵用':'Amount offset',
'张，优惠':'Zhang, discount',
'使用积分':'Using points',
'分，抵扣':'Points, deduction',
'商品总额(含运费)':'Total amount of goods (including shipping costs)',
'共优惠':'Co discount',
'应付金额':'Payable amount',
'送货至':'Delivery to',
'收货人：':'Consignee:',
'提交订单':'place order',
'发票历史信息选择':'Invoice History Information Selection',
'默认':'default',
'促销减':'Promotion reduction',
'暂不开发票':'No invoice will be issued temporarily',
'发票内容':'Invoice content',
'商品类别':'Product category',
'发票抬头':'Invoice header',
'公司':'company',
'请在此填写发票抬头':'Please fill in the invoice header here',
'发票类型':'Invoice type',
'普通发票':'Ordinary invoice',
'增值税专用发票':'Value added tax special invoice',
'单位名称':'Unit Name',
'请在此填写单位名称':'Please fill in the unit name here',
'税号':'Tax ID',
'请在此填写纳税人识别号':'Please fill in the taxpayer identification number here',
'注册地址：':'Company Registered Address',
'请输入注册地址':'Please enter the registration address',
'注册电话：':'Registration phone number:',
'请输入注册电话':'Please enter the registration phone number',
'开户银行：':'Opening Bank:',
'请输入开户银行':'Please enter the account opening bank',
'银行账户：':'Bank account:',
'请输入银行账户':'Please enter your bank account',
'收票人姓名':'Name of recipient',
'请输入收票人姓名':"Please enter the recipient's name",
'收票人电话：':"Ticket recipient's phone number:",
'请输入收票人电话':"Please enter the recipient's phone number",
'收票人地址：':'Ticket recipient address:',
'请输入收票人地址':"Please enter the recipient's address",
'请输入收票邮箱':'Please enter the ticketing email address',
'设为默认发票':'Set as default invoice',
'返回':'return',
'移除无货商品':'Remove out of stock items',
'继续下单':'Continue placing orders',
'联系方式':'Contact information',
'新增发票':'Add invoice',
'积分':'integral',
'抵扣':'Deduction',
'使用':'apply',
'暂不使用积分':'Not using points temporarily',
'使用规则':'Usage rules',
'订单大于等于':'Order greater than or equal to',
'积分支付不超过订单金额的':'Payment with points not exceeding the order amount',
'积分使用数量为':'The number of points used is',
'的整数倍':'Integer multiples of',
'积分等于':'Integral equals',
'联系方式：':'Contact information:',
'收货地址：':'Receiving address',
'更换地址':'Change address',
'新建地址':'New Address',
'订单备注':'Order remarks',
'给商家留言':'Leave a message to the merchant',
'积分选择':'Integral selection',
'暂无可选积分':'There are currently no available points to choose from',
'商品金额：':'Product amount:',
'实付款：':'Actual payment:',
'积分 +':'Integral+',
'当前积分数：':'Current product score:',
'积分不足':'Insufficient points',
'发票历史信息选择：':'Invoice history information selection:',
'发票内容：':'Invoice content:',
'发票抬头：':'Invoice header:',
'个人':'personal',
'发票信息':'Invoice Information',
'发票类型：':'Invoice type:',
'单位名称：':'Unit Name:',
'提交修改':'Submit modifications',
'注册地址':'Company Registered Address',
'注册电话':'Registration phone number',
'开户银行':'Opening Bank',
'银行账户':'bank account',
'收票人电话':"Recipient's phone number",
'收票人地址':'Recipient address',
'收票邮箱：':'Receiving email:',
'收票邮箱':'Receiving email:',
'我的优惠卷':'My coupon',
'未使用':'not used',
'已使用':'Used',
'已过期':'Expired ',
'立即使用':'Use Now',
'精选':'Selected',
'全部':'whole',
'随机券':'Random coupon',
'领取':'receive',
'已领取':'Received',
'已抢完':'Ran out',
'已抢':'Snatched',
'已抢光':'Ran out',
'立即领取':'Claim Now',
'个人中心':'Personal Center',
'订单详情':'Order details',
'开始付尾款':'Start paying the final payment',
'确认收货':'Confirm receipt',
'立即支付':'Pay Now',
'评价':'evaluate',
'取消订单':'Cancel order',
'修改地址':'Change address',
'删除订单':'Delete order',
'支付订单':'Payment Order',
'商家发货':'Merchant shipment',
'交易关闭':'Transaction closure',
'送货方式':'Delivery method',
'无需物流':'No logistics required',
'快递':'express',
'包裹':'package',
'联系电话':'Contact phone number',
'承运人':'carrier',
'联系人':'contacts',
'运单号':'Waybill number',
'暂无物流信息':'There is currently no logistics information available',
'订单信息':'Order information',
'卖家':'seller',
'收货人信息':'Consignee information',
'交易流水号':'Transaction serial number',
'订单取消备注':'Order cancellation remarks',
'商品详情':'Product details',
'商品总额':'Total amount of goods',
'满优惠':'Full discount',
'-¥':'-¥',
'运费金额':'Freight amount',
'店铺优惠券':'Shop coupons',
'实际金额':'Actual amount',
'取消订单理由':'Reason for cancellation of order',
'积分订单详情':'Points order details',
'商品合计':'Total merchandise',
'已关注':'Followed',
'关注店铺':'Follow store',
'分享':'share',
'QQ':'QQ',
'新浪':'Sina',
'微信':'WeChat',
'分享到微信':'Share on WeChat',
'已兑换':'Redeemed',
'价格':'price',
'原价':'Original price',
'商品已下架，欢迎挑选其他商品~':'The product has been taken down. Welcome to choose other products~',
'商品已下架':'The product has been delisted',
'热门礼品':'Popular Gifts',
'人气礼品':'Popular gifts',
'+￥':'+￥',
'手机下单':'Placing an order on a mobile phone',
'该商品暂无详情~':'There are currently no details for this product~',
'综合评分':'Score',
'描述相符':'Description matches',
'服务态度':'Service attitude',
'发货速度':'Shipping speed',
'服务承诺':'Service commitment',
'正品保障':'Authentic guarantee',
'客服电话':'customer service telephone numbers',
'店铺首页':'Store homepage',
'请输入关键词':'Please enter keywords',
'搜本店':'Search our store',
'我的购物车':'My shopping cart',
'本店全部分类':'All categories',
'所有商品':'All products',
'请输入...':'Please enter',
'邮箱管理':'Mailbox Management ',
'修改电子邮箱':'Modify email address',
'绑定电子邮箱':'Bind email address',
'请输入邮箱':'Please enter your email address',
'请输入正确的邮箱':'Please enter the correct email address',
'当前邮箱':'Current email',
'请输入邮箱验证码':'Please enter the email verification code',
'请输入新邮箱':'Please enter a new email address',
'下一步':'Next step',
'温馨提示':'Kind reminder',
'为了保障您的账号安全，变更重要信息需进行身份验证。':'To ensure the security of your account, changing important information requires identity verification.',
'变更过程中有任何疑问请联系在线客服解决。':'If you have any questions during the change process, please contact online customer service for resolution.',
'如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。':'If your phone number/email is no longer in use and you are unable to obtain a verification code, please contact online customer service for assistance.',
'评价订单':'Evaluate orders',
'订单：':'Order:',
'综合':'comprehensive',
'商品':'commodity',
'服务':'service',
'物流':'logistics',
'商品评分':'Product rating',
'评价晒单':'Evaluate and share the form',
'请输入内容':'Please enter the content',
'晒图':'Print a picture',
'发表评价':'Publish comments',
'交易评价/晒单':'Transaction evaluation/posting of orders',
'待评订单':'Pending evaluation orders',
'发评价，得积分，积少成多换商品':'Share reviews, earn points, accumulate less and exchange products for more',
'评价小贴士':'Evaluation Tips',
'发表评价可获得会员积分':'Posting reviews can earn member points',
'金额':'money',
'支付金额':'Payment amount',
'版权所有':'All rights reserved',
'提供技术支持':'Provide technical support',
'收藏':'collection',
'已收藏':'Favorite',
'暂无足迹':'No footprints at the moment',
'想起密码？':'Remember the password?',
'去登录':'Go log in',
'找回密码':'Retrieve password',
'请输入6～20位英文、数字、符号':'Please enter 6 to 20 English characters, numbers, and symbols',
'商品服务':'Goods and services',
'店铺热销':'Recommend',
'定金预售':'Deposit pre-sale',
'全款预售':'Full payment pre-sale',
'距离开始剩余：':'Time remaining until start:',
'距离结束剩余：':'Distance End Remaining:',
'天':'day',
'预售定金¥':'Pre sale deposit ¥',
'可抵¥':'Deductible ¥',
'拼团':'Teamwork',
'邮箱登录':'Email login',
'人团':'-person group',
' 团长优惠价￥':'Leader discount price￥',
'秒杀':'Flash sale',
'取消提醒':'Cancel reminder',
'提醒我':'Remind me',
'阶梯团':'Staircase group',
'阶梯价':'Tiered pricing',
'拼团价':'Group price',
'预售价':'Pre-sale price',
'秒杀价':'Second price reduction',
'预售价 ￥':'Pre-sale price￥',
'原价 ￥':'Original price￥',
'拼团价 ￥':'Group price￥',
'阶梯价 ￥':'Tiered pricing￥',
'秒杀价 ￥':'Second price reduction￥',
'销量':'sales volume',
'优惠券':'coupon',
'领取优惠券':'Claim coupons',
'(付尾款时间：':'(Payment deadline:',
' (发货时间：':'(Delivery time:',
'配送至':'Delivery to',
'请选择地址':'Please select an address',
'税号：':'Tax ID:',
'其他地址':'Other addresses',
'扫码参加预售':'Scan the QR code to participate in pre-sales',
'扫码开团':'Scan the QR code to open a group',
'原价购买':'Purchase at original price',
'满':'full',
'好评率':'Positive review rate',
'立即购买':'Buy Now',
'看了又看':"See it again and again",
'店铺等级':'Store level',
'商品评价':'Reviews',
'物流评价':'logistics',
'售后服务':'After Sales',
'热门收藏':'Collect',
'已售':'Sold ',
'品牌':'brand',
'查看全部':'View All',
'评分':'score',
'有图':'With a diagram',
'好评':'Positive reviews',
'中评':'Mid evaluation',
'差评':'Negative reviews',
'商家回复:':'Merchant response:',
'该商品暂无评论~':'There are currently no comments on this product~',
'暂无商品服务~':'There are currently no products or services available~',
'暂无相关商品~':'There are currently no related products available~',
'全部分类':'Classify All',
'搜索结果':'search result',
'评论数':'Number of comments',
'商品类型':'Product type',
'仅平台自营':'Only platform self operated',
'件相关商品':'Item related products',
'人气':'Popularity',
'分类':'classification',
'筛选结果':'Filter results',
'包含分类':'Include classification',
'暂无分类':'There is currently no classification available',
'热门推荐':'Popular recommendations',
'收起':'Put it away',
'更多选项':'More options',
'查看更多':'View more',
'亲，抱歉! 您查看的页面失联啦...':"Dear, I'm sorry! The page you are viewing is missing",
'您可以：去其他地方逛逛':'You can: go explore other places',
'商城首页':'Mall homepage',
'已收藏的商品':'Favorite items',
'我们正在努力装修中，敬请期待～':'We are working hard to renovate, please stay tuned',
'会员收货地址':'Member shipping address',
'超级会员':'Super member',
'立即开通':'activate now',
'请选择':'Please select',
'超级会员已到期':'Super membership has expired',
'立即续费':'Renew immediately',
'我的财产':'My Property',
'余额':'balance',
'领券':'Coupon collection',
'店铺关注':'Store Follow',
'待支付':'To be paid',
'待收货':'To be received',
'待评价':'To be evaluated',
'售后/退货':'After sales/returns',
'全部订单':'All orders',
'这里空空如也，快去挑选合适的商品吧~':'This place is empty, hurry up and choose the right products~',
'暂无商品~':'There are currently no products available~',
'暂无关注店铺~':'There are currently no following stores~',
'我的收藏':'My favorites',
'暂无收藏商品~':'There are currently no favorite products available~',
'暂无收藏商品':'There are currently no favorite products available',
'暂无足迹~':'No footprints at the moment~',
'还没注册？':'Not registered yet?',
'账号登录':'Account login',
'手机登录':'Mobile login',
'请输入账号/手机号':'Please enter your account/phone number',
'请输入密码':'Please enter password',
'登录':'Login',
'二维码已失效':'QR code has expired',
'点击刷新':'Click to refresh',
'登录成功':'Login successful',
'打开手机商城，扫描二维码':'Open the mobile shopping mall and scan the QR code',
'免输入':'Input free',
'反应快':'Quick response',
'更安全':'More secure',
'立即注册':'Register',
'主营商品':'Main products',
'忘记密码':'Forgot pwd',
'+':'+',
'店铺不存在或已关闭':'The store does not exist or has been closed',
'会员信息':'Member Information',
'用户头像':'User profile picture',
'仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。':'Only supports JPG, GIF, PNG, JPEG, BMP formats; Please keep the file size within 4.0MB.',
'会员名：':'Member Name:',
'真实姓名':'Name',
'性别：':'Gender:',
'保密':'secrecy',
'男':'male',
'女':'female',
'昵称：':'nickname',
'生日：':'Birthday:',
'年':'year',
'月':'month',
'请输入正确的手机号':'Please enter the correct phone number',
'日':'day',
'部分发货':'Partial shipment',
'保存':'Preserve',
'待发货':'To be shipped',
'已完成':'Completed',
'订单号/商品名称/店铺名称':'Order number/product name/store name',
'查看物流':'View logistics',
'物流信息':'Logistics information',
'积分订单':'Points order',
'物流方式':'Logistics methods',
'自行配送':'Self delivery',
'待付款':'Pending payment',
'商品名称/订单编号':'Product Name/Order Number',
'扫码参团':'Scan the QR code to join the delegation',
'兑换时间：':'Redemption time:',
'兑换单号: ':'Exchange Order Number:',
'标记已读':'Mark as read',
'确定删除这条消息吗？':'Are you sure to delete this message?',
'查看详情 >':'View details>',
'暂时没有消息！':'There is currently no news!',
'更多':'more',
'向上滑动浏览更多数据':'Swipe up to browse more data',
'数据加载中...':'Data loading',
'数据加载完毕~':'Data loading completed~',
'订单状态':'Order status',
'常用地址':'Common addresses',
'查看权益':'View Benefits',
'修改':'modify',
'图片':'image',
'张':' ',
'总共上传':'Total uploads',
'邮箱注册':'Email registration',
'手机注册':'Mobile registration',
'请输入短信验证码':'Please enter the SMS verification code',
'请再次输入密码':'Please enter your password again',
'请输入原密码':'Please enter the original password',
'请输入新密码':'Please enter a new password',
'请再次输入新密码':'Please enter the new password again',
'复杂的密码可使账号更安全且建议定期更换密码。':'Complex passwords can make accounts more secure and it is recommended to change passwords regularly.',
'用户发货':'User shipment',
'商品名称':'Product Name',
'购买数量':'Purchase quantity',
'服务类型':'Service type',
'物流公司':'logistics company',
'物流单号':'Logistics tracking number',
'请输入物流单号':'Please enter the logistics tracking number',
'退货地址：':'Return address:',
'提交':'Submit',
'账户管理':'Account Management',
'手机号管理':'Phone number management',
'消息':'news',
'收入':'income',
'支出':'expenditure',
'日期':'date',
'详细说明':'elaborate on',
'产品分类':'Classification',
'您好，欢迎来到':'Hello, welcome to',
'退出':'quit',
'注册':'register',
'待支付订单':'Pending Payment Order',
'待收货订单':'To be received order',
'待评价订单':'Orders to be evaluated',
'商品收藏':'Product Collection',
'店铺收藏':'Store favorites',
'我的账户':'My account',
'我的优惠券':'My coupon',
'官方客服':'Official customer service',
'服务中心':'service center',
'提现详情':'Withdrawal details',
'申请单号：':'Application number:',
'状态：':'Status:',
'提现金额：':'Withdrawal amount:',
'手续费：':'Handling fee:',
'支付宝账号：':'Alipay account:',
'真实姓名：':'Real name:',
'申请时间：':'Application time:',
'完成时间：':'Completion time:',
'失败原因：':'Reason for failure:',
'余额提现':'Withdrawal of balance',
'温馨提示：提现手续费为':'Kind reminder: The withdrawal fee is',
'最低提现金额为￥':'The minimum withdrawal amount is￥',
'提现方式：':'Withdrawal method:',
'请输入提现金额':'Please enter the withdrawal amount',
'本次提现手续费':'Withdrawal handling fee for this time',
'剩余可提现金额不足':'Insufficient remaining withdrawable amount',
'剩余可提现金额：':'Remaining withdrawable amount:',
'请输入支付宝账号':'Please enter Alipay account',
'请输入真实姓名':'Please enter your real name',
'支付密码：':'Payment password:',
'请输入支付密码':'Please enter the payment password',
'申请提现':'Apply for withdrawal',
'订单提交成功，请您尽快付款！ 订单号：':'Order submitted successfully, please make payment as soon as possible! Order number:',
'请您在提交订单后':'Please submit your order after',
'24小时内':'Within 24 hours',
'完成支付，否则订单会自动取消':'Complete payment, otherwise the order will be automatically cancelled',
'收起详情':'Storage details',
'展开详情':'Expand Details',
'商品名称：':'Product Name:',
'使用余额支付':'Payment using balance',
'（可用余额：':'(Available balance:',
'元，目前需要在线支付：':'Yuan, currently requires online payment:',
'元）余额不足？':'Is the balance of yuan insufficient?',
'马上充值':'Recharge now',
'未设置支付密码，马上去设置':'No payment password set, go set it now',
'忘记密码?':'Forgot password?',
'当前手机号 ':'Current phone number',
'微信支付':'WeChat payment',
'扫码完成支付':'Scan the code to complete the payment',
'刷新':'Refresh',
'暂无可用的支付方式，平台正在紧急处理中～':'There are currently no available payment methods, and the platform is urgently processing them',
'APP':'APP',
'请先绑定手机号，再进行支付密码操作!':'Please bind your phone number first before proceeding with the payment password operation!',
'当前手机号':'Current phone number',
'设置支付密码':'Set payment password',
'请输入原支付密码':'Please enter the original payment password',
'请再次输入新支付密码':'Please enter the new payment password again',
'修改支付密码':'Change payment password',
'请输入新手机号':'Please enter a new phone number',
'账户充值':'Account recharge',
'1.填写充值金额':'1. Fill in the recharge amount',
'2.在线支付':'2. Online payment',
'3.充值完成':'3. Recharge completed',
'填写充值金额':'Fill in the recharge amount',
'充值账户':'Recharge account',
'2.充值金额输入值必须是不小于1且不大于5000；':'2. The recharge amount input value must be no less than 1 and no more than 5000;',
'3.充值完成后，您可至会员中心查看充值记录。':'After the recharge is completed, you can go to the member center to view the recharge records.',
'请使用手机微信扫描下方二维码进行支付':'Please use your mobile phone WeChat to scan the QR code below for payment',
'您正在充值余额，请尽快支付':'You are currently recharging your balance, please make the payment as soon as possible',
'选择充值方式':'Choose recharge method',
'立即充值':'Recharge Now',
'微信支付支付':'WeChat Pay Payment',
'如二维码过期，':'If the QR code expires,',
'重新获取二维码。':'Retrieve the QR code again.',
'使用微信扫码支付':'Use WeChat QR code scanning for payment',
'暂未开启充值功能':'Recharge function not yet enabled',
'退款单号':'Refund tracking number',
'退款退货单号':'Refund and return order number',
'重新申请':'Reapply',
'提交申请':'Submit application',
'审核中':'Under review',
'完成':'complete',
'退款凭证':'Refund voucher',
'平台审核备注':'Platform Review Remarks',
'时间':'time',
'已有账号？':'Existing account?',
'注册账号':'Register an account',
'请输入图形验证码':'Please enter the graphic verification code',
'我同意':'I agree',
'《用户注册协议》':'User Registration Agreement',
'《隐私政策》':'Privacy Policy',
'已有账号，去登录':'Existing account, go log in',
'重置支付密码':'Reset payment password',
'请先绑定手机号，再进行重置支付密码操作!':'Please bind your phone number first before resetting your payment password!',
'请再次输入支付密码':'Please enter the payment password again',
'接收设置':'Receive Settings',
'接收':'receive',
'不接收':'Not accepted',
'暂时没有数据～':'There is currently no data available~',
'人购买':'People purchase',
'立即抢购':'Buy now',
'此处添加【403*高度不限】图片':'Add a 403 * height unlimited image here',
'请先绑定手机号，再进行登陆密码操作!':'Please bind your phone number first before proceeding with the login password operation!',
'添加内容':'Add content',
'图片标题':'Image Title',
'此处添加【186*340】图片':'Add a picture of [186 * 340] here',
'图片子标题':'Image Subtitle',
'此处添加【172*106】图片':'Add a [172 * 106] image here',
'此处添加【187*120】图片':'Add a 187 * 120 image here',
'个商品':'Items',
'关注':'follow',
'人已收藏':'People have collected',
'店铺已售':'Store sold',
'本店暂无商品~':'Our store currently has no products available~',
'请输入店铺名称':'Please enter the store name',
'确定删除该地址?':'Are you sure you want to delete this address?',
'提示':'prompt',
'仅退款':'Refund only',
'退货退款':'Returns and refunds',
'未收到货':'Goods not received',
'已收到货':'Received goods',
'退款退货':'Refunds and Returns',
'选择其它支付方式':'Choose another payment method',
'退款金额不可为0或者负值！':'Refund amount cannot be 0 or negative!',
'不可超过最大退款金额！':'Cannot exceed the maximum refund amount!',
'最多上传5张！':'Upload up to 5 photos!',
'提现功能已关闭':'The withdrawal function has been turned off',
'密码输入错误，请重新输入':'Password input error, please re-enter',
'最低限购1件!':'Minimum purchase limit of 1 piece!',
'数量超出购买范围！':'Quantity exceeds the purchase range!',
'超过购买限制!':'Exceeded purchase limit!',
'移入收藏夹成功！':'Successfully moved to favorites!',
'删除成功！':'Delete successful!',
'清空失效商品成功！':'Empty invalid products successfully!',
'结算商品不能为空！':'Settlement item cannot be empty!',
'在线':'on line',
'请选择图片类型文件':'Please select an image type file',
'是否要发送剪切板的图片?':'Do you want to send a picture of the clipboard?',
'账号已登出':'Account logged out',
'warning':'warning',
'离线':'off-line',
'[图片]':'[Image]',
'[商品]':'[Product]',
'[订单]':'[Order]',
'确定取消收藏吗?':'Are you sure to cancel the collection?',
'请选择商品':'Please select the product',
'邮箱格式不正确，请重新输入！':'The email format is incorrect, please re-enter!',
'请填写正确的税号':'Please fill in the correct tax number',
'请填正确填写注册电话':'Please fill in the registration phone number correctly',
'请填正确填写收票人电话':"Please fill in the correct recipient's phone number",
'请填写发票抬头':'Please fill in the invoice header',
'请填写收票邮箱':'Please fill in the ticket receiving email address',
'请填写单位名称':'Please fill in the unit name',
'设置':'set up',
'添加标题':'Add title',
'请填写注册电话':'Please fill in the registration phone number',
'请正确填写注册电话':'Please fill in the registration phone number correctly',
'请填写开户银行':'Please fill in the account opening bank',
'不可超出最大申请数量':'Cannot exceed the maximum number of applications',
'请填写收票人姓名':"Please fill in the recipient's name",
'请填写收票人电话':"Please fill in the recipient's phone number",
'请正确填写收票人电话':"Please fill in the recipient's phone number correctly",
'请填写收票人地址':"Please fill in the recipient's address",
'商品促销信息发生变化，请返回后重新下单':'The promotional information of the product has changed. Please return and place a new order',
'商品促销信息发生变化':'Changes in product promotion information',
'提交订单失败，请稍后重试':'Order submission failed, please try again later',
',2s后自动跳转订单列表':'Automatically jump to the order list after 2 seconds',
'请输入正确的':'Please enter the correct',
'领取成功':'Successfully claimed',
'取消该订单定金不予退还,确定取消?':'The deposit for canceling this order will not be refunded. Are you sure you want to cancel?',
'请选择取消理由':'Please select the reason for cancellation',
'取消订单成功':'Cancel order successful',
'确认删除该订单?':'Are you sure to delete this order?',
'删除订单成功':'Successfully deleted order',
'确认收货?':'Confirm receipt?',
'确认收货成功':'Confirm successful receipt of goods',
'切换地址成功':'Successfully switched addresses',
'下单':'Place an order',
'请输入邮箱号':'Please enter your email number',
'评价成功':'Evaluation successful',
'上传图片失败':'Failed to upload image',
'确定要清空吗?':'Are you sure you want to clear it?',
'从商品详情页进入':'Enter from the product details page',
' 点这里':'Click here',
'请输入正确的账号/手机号':'Please enter the correct account/phone number',
'上传图片成功':'Successfully uploaded image',
'图像应小于4MB':'The image should be less than 4MB',
'从订单列表进入':'Enter from the order list',
'消息列表':'Message List',
'请填写纳税人税号':"Please fill in the taxpayer's tax number",
'订单消息':'Order message',
'资产消息':'Asset news',
'售后消息':'After sales news',
'预约提醒':'Appointment reminder',
'确定删除这些消息吗':'Are you sure to delete these messages',
'请先选择要操作的消息！':'Please select the message you want to operate on first!',
'请先绑定手机号，再进行操作!':'Please bind your phone number first before proceeding!',
'请输入旧密码':'Please enter your old password',
'请输入一致的新密码':'Please enter a consistent new password',
'请输入一致的密码':'Please enter a consistent password',
'请填写银行账户':'Please fill in your bank account',
'请输入物流单号！':'Please enter the logistics tracking number!',
'请输入正确的物流单号！':'Please enter the correct logistics tracking number!',
'请输入正确的支付密码':'Please enter the correct payment password',
'请选择支付方式':'Please select payment method',
'支付成功,2s后自动跳转订单列表':'Payment successful, automatically redirect to order list after 2 seconds',
'请输入旧支付密码':'Please enter your old payment password',
'请输入新支付密码':'Please enter a new payment password',
'请输入一致的新支付密码':'Please enter a consistent new payment password',
'请输入一致的支付密码':'Please enter a consistent payment password',
'请输入充值金额':'Please enter the recharge amount',
'超过充值金额上限':'Exceeding the upper limit of recharge amount',
'充值成功,2s后自动跳转充值列表':'Recharged successfully, automatically redirected to the recharge list after 2 seconds',
'充值成功,2s后自动跳转支付页面':'Recharged successfully, automatically redirected to payment page after 2 seconds',
'请同意用户注册协议及隐私政策':'Please agree to the user registration agreement and privacy policy',
'从店铺详情页进入':'Enter from the store details page',
'请填写注册地址':'Please fill in the registration address',
'请选择物流公司!':'Please select a logistics company!',
'系统消息':'System messages',
'请输入5~40个字符':'Please enter 5-40 characters',

    '记住密码': 'Remember password',
    '交易中心': 'Trading Center',
    '关注中心': 'Focus Center',
    '客户服务': 'Customer Service',
    '会员中心': 'Member Center',
    '我的钱包': 'My Wallet',
    '店铺': 'Shop',
    '请输入账号/手机号/邮箱': 'Please enter your account/phone number/email',
    '发票内容将显示本单商品所属类别及价格信息，发票金额为实际支付金额，不含优惠等扣减金额。': 'The invoice content will display the category and price information of the goods in this order. The invoice amount is the actual payment amount, excluding discounts and other deductions.',
    '发票内容将显示商品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额': 'The invoice content will display the product name and price information, and the invoice amount is the actual payment amount, excluding discounts and other deductions',
    '请输入正确的电话号码': 'Please enter a correct phone number',
    '接收语言：':'Receiving Language:',
    '请选择区号':'Please select an area code',
    '手机号找回':'Phone number recovery',
    '邮箱找回':'Mailbox recovery',
    '企业': 'Enterprise',
    '货币选择': 'Currency selection',
    '积分兑换订单': 'Points redemption order',
    '我的积分': 'My points',
    '安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合': 'A password with high security can ensure the security of your account. It is recommended to have a password of 6 to 20 characters, consisting of a combination of English, numbers, or symbols',
    '选择接收语言后，消息通知将以该种语言推送': 'After selecting the receiving language, message notifications will be pushed in that language',

};
