/*
 * @Author: cuihaopeng cuihaopeng@slodon.cn
 * @Date: 2024-03-16 15:19:04
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2024-12-17 16:25:17
 * @FilePath: /bzb_duoyuyan_pc/src/utils/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  项目的配置文件
 */
export const apiUrl = 'https://www.findtobuy.com';//接口请求地址
export const chatUrl = 'ws://im.slodon.cn';//客服地址
export const mUrl = 'http://m.slodon.cn/'//移动端网页地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = 'slodon';//浏览器顶部title
export const gdKey = '9d5e1fb47c9a1375677c0cac6084875e';//高德web-js key
export const gdSecurityCode = '7039dd3dc5377d37134faf3791f6ce74'//高德web-js 安全密钥
export const statShowDebug = true;//是否开启统计的调试

/** copyright *** slodon *** version-v4.6.1 *** date-2023-02-28 ***主版本v4.6.1**/



