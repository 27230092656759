<!--
 * @Author: cuihaopeng cuihaopeng@slodon.cn
 * @Date: 2024-09-18 15:13:56
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2024-11-22 17:50:19
 * @FilePath: /yazhiliang_pc/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <router-view />
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
import { useStore } from 'vuex';
export default {
  name: 'App',

  setup() {
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const configInfo = reactive({ data: {} })
    // 获取系统配置信息
    const getSystemConfigInfo = () => {
      proxy.$get("v3/system/front/setting/getSettings", {
        names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name,basic_site_icp,basic_site_copyright,basic_site_technical_support,platform_customer_service_name,platform_customer_service_logo"
      }).then(res => {
        if (res.state == 200) {
          configInfo.data = {
            main_site_logo: res.data[0],
            main_user_center_logo: res.data[1],
            main_user_logon_bg: res.data[2],
            main_user_register_bg: res.data[3],
            pc_home_bottom_adv: res.data[4],
            main_user_forget_password_bg: res.data[5],
            basic_site_name: res.data[6],
            basic_site_icp: res.data[7],
            basic_site_copyright: res.data[8],
            basic_site_technical_support: res.data[9],
            platform_customer_service_name: res.data[10],
            platform_customer_service_logo: res.data[11]
          }
          store.commit('updateConfigInfo', configInfo.data);
        }
      })
    };

    const langTotalList = [
      { name: '中文', val: 'zh', img: require('@/assets/flag/China.png') },
      { name: '阿拉伯', val: 'ara', img: require('@/assets/flag/Arab.png') },
      { name: '香港', val: 'cht', img: require('@/assets/flag/HK.png') },
      { name: '意大利', val: 'it', img: require('@/assets/flag/Italy.png') },
      { name: '韩国', val: 'kor', img: require('@/assets/flag/Korea.png') },
      { name: '葡萄牙', val: 'pt', img: require('@/assets/flag/Portugal.png') },
      { name: '俄罗斯', val: 'ru', img: require('@/assets/flag/Russia.png') },
      { name: '泰国', val: 'th', img: require('@/assets/flag/Thailand.png') },
      { name: 'Deutsch', val: 'de', img: require('@/assets/flag/Deutsch.png') },
      { name: 'Espanol', val: 'spa', img: require('@/assets/flag/Espanol.png') },
      { name: 'French', val: 'fra', img: require('@/assets/flag/French.png') },
      { name: 'English', val: 'en', img: require('@/assets/flag/English.png')},
      { name: 'Japanses', val: 'jp', img: require('@/assets/flag/Japanses.png')},
    ]

    onMounted(() => {
      getSystemConfigInfo()
      localStorage.setItem('langTotalList', JSON.stringify(langTotalList))
    })
  }

}
</script>

<style lang="scss">
@import "./style/reset.scss";
@import "./style/base.scss";

//放大镜位置
.mouse-cover-canvas {
  position: absolute;
  top: 173px !important;
  left: 740px !important;
}
.wpwl-control {
    height: 34px;
}
</style>
