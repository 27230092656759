
import dynaIcon from './dynaIcon'
import { CheckBox } from './JsxMiniComponent'


export default {
     install (app) {
          app.component('dynaIcon', dynaIcon)
          app.component('myCheckBox', CheckBox)
     },
}